export default function setupUnifiedMobileMenu() {
  const menuRef = document.querySelector("#unified_mobile_menu")
  if (!menuRef) return

  let opened = false
  const updateOpenStatus = state => {
    opened = state
    if (opened) {
      window.document.body.classList.add("mobile-menu-opened")
      menuRef.classList.add("open")
      menuRef.classList.remove("closed")
    } else {
      window.document.body.classList.remove("mobile-menu-opened")
      menuRef.classList.add("closed")
      menuRef.classList.remove("open")
    }
  }

  menuRef.addEventListener("click", e => {
    updateOpenStatus(!opened)
  })
}
