import ProsumerHome from "app/components/prosumer_home"
import LazyBGImageObserver from "app/scripts/lazy_bg_img_observer"
import setupLazyLoad from "app/scripts/setup_lazy_load"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import { setAppReady } from "shared/lib/utils"

Object.assign(window.App.Scripts, {
  LazyBGImageObserver,
  SetupUnifiedMobileMenu,
  ProsumerHome,
  SetupUnifiedHeaderDropdown,
  setupLazyLoad,
})

setAppReady()
